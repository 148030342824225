import React from 'react'
import Navbar from '../components/Navbar'
import logo from '../Assets/Logo2.png'
import Footer from '../components/Footer'
import Aos from 'aos'
import { useEffect } from 'react'
import meet from '../Assets/meet.png'
import a from '../Assets/avishkar.png'
import b from '../Assets/aryan.png'
import c from '../Assets/anushka.png'
import d from '../Assets/hardik.png'
import e from '../Assets/niramya.png'
import { motion } from 'framer-motion'
import { AnimatePresence } from 'framer-motion'
import '../style/about.css'

function About() {
  useEffect(()=>{
    Aos.init({
      duration:1000
    })
  })
  return (
    <>
    <AnimatePresence>
    <motion.div className='about1' initial={{opacity:0}}
        animate={{opacity:1}}
        transition={{ ease: "easeInOut", duration: 0.7 }}
        exit={{opacity:0}}>
    <div style={{position:"fixed", width:"100%"}}>
    <Navbar></Navbar>
    </div>
    <div className='container-fluid aboutco'>
        <div className='row w-100'>
            <div className='col-sm-6 text-center'>
                <img  src={logo} className='logst' data-aos="zoom-in"
    data-aos-easing="ease-in-out"
    data-aos-once="true"></img>
            </div>
            <div className='col-sm-6 text-center roabout'>
            <h1>WHO ARE WE?</h1>
            <p>Design is where art and science breaks even. Which has led to our team of engineers and designers collaborating to establish a company offering various services. Our expertise includes interface design, 3D modeling, and game production, among other project types, and we are eager to undertake new ventures in these areas.</p>
            </div>
        </div>
    </div>
    <div className='container-fluid' style={{width:"100%"}}>
    <div className='row aboutsecrow'>
        <h1 className='text-center'>MEET THE TEAM </h1>
    </div>
    <div className='row aboutsecrow2 text-center'>
        {/* <img src={meet}></img> */}
        <div className='col-sm-4'>
          <img src={a} style={{width:"80%"}}></img>
        </div>

        <div className='col-sm-4'>
          <img src={b} style={{width:"80%"}}></img>
        </div>

        <div className='col-sm-4'>
          <img src={c} style={{width:"80%"}}></img>
        </div>
        </div>
<div className='row aboutsecrow2 text-center' >        <div className='col-sm-4'>
          <img src={d} style={{width:"80%"}}></img>
        </div>

        <div className='col-sm-4'>
          <img src={e} style={{width:"80%"}}></img>
        </div>
        </div>

    
    </div>
      <Footer></Footer>
    </motion.div>
    </AnimatePresence>
    </>
  )
}

export default About
