import React, { useState } from 'react'
import '../style/assests.css'
import a from '../Assets/ass/Bofors.jpg'
import b from '../Assets/ass/M4.jpg'
import c from '../Assets/ass/Military base (2).png'
import d from '../Assets/ass/Tank.jpg'
import e from '../Assets/ass/UMP.jpg'

function Assets() {
  const [pic,setpic]=useState(a);
  const picset = (val) =>{
    setpic(val)
  }
  return (
    <>
    <div className='container-fluid'> 
    <div className='container' style={{marginTop:"5vh",marginBottom:"5vh"}}>
      <div className='row'>
        <p className='xar'>  ASSETS FOR SALE</p>
      </div>
    </div>
      <div className='container' style={{backgroundColor:"#17191A"}}>
        <div className='row'>
          <div className='col-sm-6' style={{justifyContent:"center",alignItems:"center",display:"flex"}}>
            <img src={pic} style={{padding:"20px",width:"100%"}}></img>
          </div>
          <div className='col-sm-6 pics'>
          <div className='row'>
          
            <div className='col' style={{display:"flex",margin:"10px",flexDirection:"row"}}>
              <div><img onClick={()=>{picset(b)}}src={b}></img></div>
            
              <div><p className='x2'>3D Model</p>
             
              <p className='x1'><a href={'https://sketchfab.com/3d-models/m4a1-430cecb5473e4034b31a37d9e6f33ab2'} style={{textDecoration:"none"}}>M4A1</a></p> </div>
            </div>

            <div className='col' style={{display:"flex",margin:"10px"}}>
              <div><img onClick={()=>{picset(c)}}  src={c}></img></div>
              <div><p className='x2'>3D Model</p>
              
              <p className='x1'><a href={'https://assetstore.unity.com/packages/3d/environments/ultimate-military-base-pack-250714'} style={{textDecoration:"none"}}>Military Base</a></p></div>

            </div>


            <div className='col' style={{display:"flex",margin:"10px"}}>
              <div><img onClick={()=>{picset(e)}} src={e}></img></div>
              <div><p className='x2'>3D Model</p>
              <p className='x1'><a href={'https://sketchfab.com/3d-models/ump-gun-163819ac0b0246459fb7e21aff885908'} style={{textDecoration:"none"}}>UMP Gun</a></p></div>
            </div>

            <div className='col' style={{display:"flex",margin:"10px"}}>
              <div><img onClick={()=>{picset(a)}}  src={a}></img></div>
              <div><p className='x2'>3D Model</p>
              <p className='x1'><a href={'https://sketchfab.com/3d-models/bofors-155mm-eb60e9cc1a3a44319fba11bfb1d44557'} style={{textDecoration:"none"}}>Bofors 155mm</a></p></div>
            </div>

            <div className='col' style={{display:"flex",margin:"10px"}}>
              <div><img onClick={()=>{picset(d)}}  src={d}></img></div>
              <div><p className='x2'>3D Model</p>
              <p className='x1'><a href={'https://sketchfab.com/3d-models/centurions-tank-e8c9856a6c384d7d9598ad29f39e5734'} style={{textDecoration:"none"}}>Centurions Tank</a></p></div>
            </div>
          </div>
            
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Assets
