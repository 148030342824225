import React from 'react'
import '../style/portfolio.css'
import a from '../Assets/image22.png'
import b from '../Assets/browser 5.png'
import Showcase from '../components/Showcase'
import {motion} from 'framer-motion'
import { AnimatePresence } from 'framer-motion'
import kd from '../Assets/image 42.png'
import vr from '../Assets/image 43.png'
import fort from '../Assets/1b.png'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

function Portfolio() {
  return (
    <>
    <AnimatePresence>
    <motion.div className='cot' initial={{opacity:0}}
        animate={{opacity:1}}
        transition={{ ease: "easeInOut", duration: 0.7 }}
        exit={{opacity:0}}>
      <div className='bli'>
      <div style={{ position: "fixed", width: "100%" ,zIndex:100}}>
                        <Navbar></Navbar>
                    </div>
      </div>

      <div className='headtext'>
      {/* <h1 className='p1ad'>COMING</h1>
      <h1 className='p2ad'>SOON</h1> */}

      </div>
    </motion.div>
        <div className='container-fluid' style={{marginTop:"10vh"}}>
        <div className='container'>
            <div className='row'>
                <div className='col-sm-3'>
                    <img src={a} style={{width:"100%"}}></img>
                </div>
                <div className='col-sm-9 righ'>
                    {/* <div style={{display:"flex",marginTop:"10px"}}>
                        <img src={b} style={{width:"40px"}}></img>
                        <div>   <p style={{marginTop:"1px",color:"#CE3C46"}}>NEW UPCOMING GAME</p></div>

                    </div> */}
                    <div style={{marginTop:"5px"}}>
                        <h1 style={{color:"#CE3C46",fontWeight:"bold",fontSize:"x-large"}}>GONNE WRONG (Coming Soon)</h1>
                        <p style={{marginTop:"10px",color:"white",fontWeight:"normal",fontSize:"medium"}}>Gonne wrong is all about awesome story and stunning visuals. Its a zombie based story-mode game. This game has wide range of zombies with different abilities which progresses along the story. You will have to find weapons and other needed things in the environment and fight zombies. The game features 9 different locations which are connected by story . Smooth mechanism of Melee weapons and very realistic gunplay will give you your best experience with the gameplay. The game has 5 main NPC characters which will help you throughout the story.</p>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <div className='container-fluid' style={{marginTop:"10vh"}}>
            <div className='container '>
                <div className='row '>
                    <div className='col-sm-4'>
                    <div class="card "  >
  <img src={vr} class="card-img-top" alt="..."/>
  <div class="card-body">
    <h5 class="card-title">VEER GATHA MUSEUM</h5>
    <p class="card-text">A VR army museum experience with a lots of discovery to be made inside</p>
    <a href="#" class="">see more</a>
  </div>
</div>
                    </div>

                    <div className='col-sm-4'>
                    <div class="card"  >
  <img src={fort} class="card-img-top" alt="..."/>
  <div class="card-body">
    <h5 class="card-title">OMAN EXPERIENCE</h5>
    <p class="card-text">A deserted VR experience which displays the heritage of Oman</p>
    <a href="#" class="">see more</a>
  </div>
</div>
                    </div>

                    <div className='col-sm-4'>
                    <div class="card"  >
  <img src={kd} class="card-img-top" alt="..."/>
  <div class="card-body">
    <h5 class="card-title">KEDARNATH</h5>
    <p class="card-text">A fully 3D modelled VR Kedarnath Tourism made to experience places without being there</p>
    <a href="#" class="">see more</a>
  </div>
</div>
                    </div>

                </div>
            </div>
        </div>
        <div style={{marginTop:"10vh"}}>
          <Showcase></Showcase>
        </div>
        <div style={{marginTop:"10vh"}}>
          <Footer></Footer>
        </div>
        </AnimatePresence>
    </>
  )
}

export default Portfolio
