import React, { useState } from 'react'
import '../style/case.css'
import a from '../Assets/pic/1 (1).jpg'
import b from '../Assets/pic/1 (2).jpg'
import c from '../Assets/pic/1 (3).jpg'
import d from '../Assets/pic/1 (4).jpg'
import e from '../Assets/pic/1 (5).jpg'
import f from '../Assets/pic/1 (6).jpg'
import g from '../Assets/pic/1 (7).jpg'
import a1 from '../Assets/pic/1a.png'
import a2 from '../Assets/pic/1b.png'
import a3 from '../Assets/pic/1c.png'
import a4 from '../Assets/pic/1d.png'
import a5 from '../Assets/pic/1e.png'
function Showcase() {
    const photos=[
        {
            id:'0',
            type:'art',
            url:a
        },
        {
            id:'1',
            type:'art',
            url:b
        },
        {
            id:'2',
            type:'art',
            url:c
        },
        {
            id:'3',
            type:'art',
            url:d
        },
        {
            id:'4',
            type:'art',
            url:e
        },
        {
            id:'5',
            type:'art',
            url:f
        },
        {
            id:'6',
            type:'art',
            url:g
        },
        {
            id:'7',
            type:'arvr',
            url:a1
        }, {
            id:'8',
            type:'arvr',
            url:a2
        },
        {
            id:'9',
            type:'arvr',
            url:a3
        },
        {
            id:'10',
            type:'arvr',
            url:a4
        }, {
            id:'11',
            type:'arvr',
            url:a5
        },
        
    ]

    const [filteredphots,setfilter]=useState(photos)

    const filterfunction=(str)=>{
        if(str === "Game"){
            setfilter(photos.filter((item)=>
            item.type.toLowerCase().includes(str.toLowerCase())
            ))
        }

        else if(str==="art"){
            setfilter(photos.filter((item)=>
            item.type.toLowerCase().includes(str.toLowerCase())
            ))
        }

        else if(str==='arvr')
        {
            setfilter(photos.filter((item)=>
            item.type.toLowerCase().includes(str.toLowerCase())
            ))
        }
    }
  return (
    <>
    <div className='container-fluid'>
      <div className='container'>
        <div className='row'>
            <div className='col-sm-4 text-center' style={{justifyContent:"center",alignItems:"center",display:"flex"}}><div className='caser'><p>Game Developement</p></div></div>
            <div onClick={()=>{filterfunction("art")}} className='col-sm-4 text-center' style={{justifyContent:"center",alignItems:"center",display:"flex"}}><div className='caser'><p>3D Art</p></div></div>
            <div onClick={()=>{filterfunction("arvr")}} className='col-sm-4 text-center' style={{justifyContent:"center",alignItems:"center",display:"flex"}}><div className='caser'><p>VR/AR</p></div></div>
        </div>
      </div>
    </div>
    <div className='container'>
    <div className='container'>
        <div className='row'>
        {
            filteredphots.map((item,index)=>{
                return (
                    <div className='col text-center' style={{margin:"5px",width:"100%"}} key={item.id}>
                        <img className='rio' src={item.url}></img>
                    </div>
                )
            })
        }
        </div>
    </div>
    </div>
    </>
  )
}

export default Showcase
