import React from 'react'
import cimg from '../Assets/coimage.png'
import { motion } from 'framer-motion'
import Footer from '../components/Footer'
import '../style/contact.css'
import Navbar from '../components/Navbar'
import { useRef } from 'react'
import emailjs from '@emailjs/browser'
import a from '../Assets/Ellipse.png'
import b from '../Assets/circle.png'
import { AnimatePresence } from 'framer-motion'
function Contact() {

    const form=useRef();

    


    const sendEmail=(e)=>{
        console.log('ffirs')
        e.preventDefault();
        console.log('ffirsttt')

        emailjs.sendForm('service_duufqhf', 'template_biarjia', form.current, 'w1wkqqEbhQyf8Ojgj')
      .then((result) => {
          console.log(result.text);
          alert('Agni Studios will connect with you.')
          window.location.replace('/')
      }, (error) => {
          console.log(error.text);
      });
        
    }

    return (
        <>
        <AnimatePresence>
            <motion.div className='cohead' initial={{opacity:0}}
        animate={{opacity:1}}
        transition={{ ease: "easeInOut", duration: 0.7 }}
        exit={{opacity:0}}>
                <div className='blim'>
                    <div style={{ position: "fixed",zIndex:100, width: "100%" }}>
                        <Navbar></Navbar>
                    </div>
                    <div className='container' style={{ width: "100%", height: "89vh", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }}>
                        <h1 className='coh'>Contact Us</h1>
                        <div><p className='text-center poh'>We would love to hear from you!</p></div>
                        <p style={{color:"#d4d4d4",fontSize:"large"}}>business.agnistudios@gmail.com</p>
                        <p style={{color:"#d4d4d4",fontSize:"large"}}>+919322845128</p>
                    </div>
                </div>
            </motion.div>
            <div className='container-fluid cax'>
                <div className='row'>
                    <div className='secco col-sm-3'>
                        <div style={{ position: "absolute", bottom: "20px", left: "25%" }}> <img style={{ width: "220px" }} src={a}></img></div>
                        <div style={{ position: "absolute", bottom: "50px", left: "10%" }}> <img style={{ width: "120px" }} src={b}></img></div>
                    </div>
                    <div className='col-sm-9' style={{position:"relative"}}>
                        <div className='container'>
                            <form ref={form} onSubmit={sendEmail}>
                                <div className='row rosc'>
                                    <div className='col-sm-6'>
                                        <p>First Name</p>
                                        <input type='text' name="user_first" ></input>
                                    </div>
                                    <div className='col-sm-6'>
                                        <p>Last Name</p>
                                        <input type='text' name="user_last" ></input>
                                    </div>
                                </div>

                                <div className='row rosc1'>
                                    <div className='col-sm-6'>
                                        <p>Email</p>
                                        <input type='text' name="user_email" ></input>
                                    </div>
                                    <div className='col-sm-6'>
                                        <p>Phone No.</p>
                                        <input type='text' name="user_phone" ></input>
                                    </div>
                                </div>
                                <div className='row rab'>
                                    <p>What services do you wish from us </p>
                                    <div className='row'>
                                    <div className='col-sm-4 my-2'>
                                        <input type="radio" id="contactChoice1" name="contact" value="email" />
                                        <label for="contactChoice1" >3D Environment/Props</label>
                                        </div>
                                        <div className='col-sm-4 my-2'>
                                        <input type="radio" id="contactChoice2" name="contact" value="phone" />
                                        <label for="contactChoice2">Game Developement</label>
                                        </div>
                                        <div className='col-sm-4 my-2'>
                                        <input type="radio" id="contactChoice3" name="contact" value="mail" />
                                        <label for="contactChoice3">AR/VR</label>
                                        </div>

                                        <div className='col-sm-4 my-2'>
                                        <input type="radio" id="contactChoice3" name="contact" value="mail" />
                                        <label for="contactChoice3">Web Developement</label>
                                        </div>

                                        <div className='col-sm-4 my-2'>
                                        <input type="radio" id="contactChoice3" name="contact" value="mail" />
                                        <label for="contactChoice3">App Developement</label>
                                        </div>

                                        <div className='col-sm-4 my-2'>
                                        <input type="radio" id="contactChoice3" name="contact" value="mail" />
                                        <label for="contactChoice3">Software Developement</label>
                                        </div>

                                        
                                    </div>
                                </div>

                                <div className='row thirr'>
                                <div>
                                    <p>Additional Messsage</p>
                                    <input type='text' name="message" ></input>
                                    <div>
                                    <button type="submit" value="Send"  class="btnl btn btn-danger">Send Message</button>
                                    </div>
                                </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{marginTop:'80px'}}>
                <Footer></Footer>
            </div>
            </AnimatePresence>
        </>
    )
}

export default Contact
