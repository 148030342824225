import React from 'react'
import '../style/navbar.css'
import { useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import logo from '../Assets/fotlogo.png'
function Navbar() {

  const [navbar, setNavbar] = useState(false)

  const changeBackground = () => {
    console.log(window.scrollY)
    if (window.scrollY >= 66) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  useEffect(() => {
    changeBackground()
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground)
  })

  
  return (
    <div className={navbar ? "naz" : "na"} >
      <nav class=" navbar navbar-expand-lg" >
  <div class="container-fluid">
    <Link style={{textDecoration:"none"}} class="navbar-brand" to="/"><img src={logo} style={{width:"100px"}}></img></Link>
    <button style={{color:"white"}} class="navbar-toggler .custom-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon .custom-toggler"></span>
    </button>
    <div class="collapse navbar-collapse " id="navbarSupportedContent">
      <ul class="navbar-nav  ms-auto mb-2 mb-lg-0">
        <li class="nav-item mx-4">
          <Link style={{textDecoration:"none"}} to='/' class="nav-link " aria-current="page" href="#">HOME</Link>
        </li>

        <li class="nav-item mx-4">
          <Link style={{textDecoration:"none"}} to='/portfolio' class="nav-link " aria-current="page" href="#">PORTFOLIO</Link>
        </li>

        <li class="nav-item mx-4">
          <Link style={{textDecoration:"none"}} to='/about' class="nav-link " aria-current="page" href="#">ABOUT</Link>
        </li>

        {/* <li class="nav-item mx-4">
          <a class="nav-link " href="#">ASSETS</a>
        </li> */}
       
        <li class="nav-item mx-4">
          <Link style={{textDecoration:"none"}} to='/contact' class="nav-link">CONTACT US</Link>
        </li>
      </ul>
     
    </div>
  </div>
</nav>
    </div>
  )
}

export default Navbar
