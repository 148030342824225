import React from 'react'
import a from '../Assets/Bofors.png'
import b from '../Assets/Kedarnath.png'
import c from '../Assets/UMP Image.png'
import d from '../Assets/Military base.png'
import '../style/slider.css'


function Slider() {

  
    return (
        <>

            <div className='scrollingh'>
                <div className='imgs'><img style={{width:"100%"}}  src={a}></img>
                <div className='x11'><p>3D Artillery</p></div>
                </div>
                <div className='imgs text-center'><img style={{width:"100%"}} src={b}></img>
                <div className='x11'><p className='p1'>Kedarnath VR</p></div>
                </div>
                <div className='imgs'><img style={{width:"100%"}} src={d}></img>
                <div className='x11'><p className='p2' style={{left:"25%"}}>Ultimate military base pack</p></div>
                </div>
                <div className='imgs'><img style={{width:"100%"}} src={c}></img>
                <div className='x11'><p>3D Weapon</p></div>
                </div>
            </div>
        </>
   
    );
}

export default Slider
