import React from 'react'
import '../style/footer.css'
import facebook from '../Assets/facebook.png'
import a from '../Assets/fotlogo.png'
import insta from '../Assets/instagram.png'
import twitter from '../Assets/twitter.png'
import tik from '../Assets/tiktok.png'
import tele from '../Assets/telegram.png'
import wt from '../Assets/whatsapp.png'
import lkd from '../Assets/linkedin.png'
import yt from '../Assets/youtube.png'
import { Link } from 'react-router-dom'
function Footer() {
  return (
    <>
    <div className='container-fluid' style={{backgroundColor:"#1D1F21"}}>
    <div class=" pt-5 pb-5 footer" style={{padding:"0px",marginTop:"0px"}}>
<div class="container">
<div className='line1'></div>
  <div class="row">
    <div class="col-sm-6 about-company">
      <img src={a} style={{width:"150px"}}></img>
      {/* <hr class="hre"></hr> */}
      <div className='le' >
      <p class="pr-5 text-white" style={{width:"220px"}}>Agni Studios,<span><br></br>
Bringing virtual world to life, one pixel at a time</span></p>
<p class="pr-5 cl">+91 9322845128</p>
<p class="pr-5 cl" style={{paddingTop:0}}>business.agnistudios@gmail.com</p>
      {/* <p><a href="#"><i class="fa fa-facebook-square mr-1"></i></a><a href="#"><i class="fa fa-linkedin-square"></i></a></p> */}
      <p className='cl'>Social Media</p>
      <div>
        <div className='' style={{display:"flex"}}>
          
        </div>

        <div className='' style={{display:"flex"}}>
        <div style={{width:"35px",margin:"5px",borderRadius:"7px",justifyContent:"center",alignItems:"center",display:"flex",backgroundColor:"black"}} className='co'><a style={{textDecoration:"none"}} href={'https://www.youtube.com/channel/UCZxNBnCyfSSWxLWw6A6bXnQ'}><img style={{width:"35px"}} src={yt}></img></a></div>
        <div style={{width:"35px",margin:"5px",borderRadius:"7px",justifyContent:"center",alignItems:"center",display:"flex",backgroundColor:"black"}} className='co'><a style={{textDecoration:"none"}} href={'https://www.instagram.com/agni_studios/?hl=en'}><img style={{width:"25px"}} src={tele}></img></a></div>
        <div style={{width:"35px",margin:"5px",borderRadius:"7px",justifyContent:"center",alignItems:"center",display:"flex",backgroundColor:"black"}} className='co'><img style={{width:"25px"}} src={wt}></img></div>
        <div style={{width:"35px",margin:"5px",borderRadius:"7px",justifyContent:"center",alignItems:"center",display:"flex",backgroundColor:"black"}} className='co'><a style={{textDecoration:"none"}} href={'https://www.linkedin.com/feed/'}><img style={{width:"25px"}} src={lkd}></img></a></div>
        <div className='co'><img style={{width:"35px",margin:"5px",borderRadius:"7px"}}  src={facebook}></img></div>
          <div className='co'><a style={{textDecoration:"none"}} href={'https://www.instagram.com/agni_studios/?hl=en'}><img style={{width:"35px",margin:"5px",borderRadius:"7px",backgroundColor:"black"}} src={insta}></img></a></div>
          <div style={{width:"35px",margin:"5px",borderRadius:"7px",justifyContent:"center",alignItems:"center",display:"flex",backgroundColor:"black"}} className='co'><img style={{width:"25px"}} src={twitter}></img></div>
        </div>

      </div>

      </div>
    </div>
    
    <div class="col-sm-3 links">
      <h4 class=" mt-lg-0 mt-sm-3 hl">LINKS</h4>
        <ul style={{listStyleType:"none"}} class="m-0 p-0">
          <li className="p" style={{marginTop:"30px"}}> <a  href={'https://agnistudios.in/'}>HOME</a></li>
          <li className='p my-3'> <a className=''  href={'https://agnistudios.in/about'}>ABOUT</a></li>
          <li className='p my-3'> <a href={" href={'https://agnistudios.in/portfolio'}"}>PORTFOLIO</a></li>
          {/* <li className='p my-3'> <a href="#">ASSETS</a></li> */}
          <li className='p my-3'> <a  href={'https://agnistudios.in/contact'}>CONTACT US</a></li>
        </ul>
    </div>
    

    <div class="col-sm-3 links">
      <h4 class=" mt-lg-0 mt-sm-3 hl">SERVICES</h4>
        <ul style={{listStyleType:"none"}} class="m-0 p-0">
          <li className="p" style={{marginTop:"30px"}}> <a  href="#">Game Development</a></li>
          <li className='p my-3'> <a className='' href="#">VR/AR Development</a></li>
          <li className='p my-3'> <a  href="#">3D Environments</a></li>
          <li className='p my-3'> <a  href="#">Software Development</a></li>
          <li className='p my-3'> <a  href="#">HTML5</a></li>
        </ul>
    </div>
    <div className='line2'></div>
    <div class="row" style={{justifyContent:"center"}}>
    <div class="col copyright text-center">
      <p class="copyrightfo"><small class="text-center">copyright@ Agni Studios 2023. All rights reserved</small></p>
    </div>
  </div>
    </div>
  </div>
  
 
</div>
</div>
    {/* </div> */}
    </>
  )
}

export default Footer
