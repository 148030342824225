import React, { useEffect } from 'react'
import Footer from '../components/Footer'
import a from '../Assets/browser 1 (1).png'
import vr from '../Assets/browser 5.png'
import Mobile from '../components/Mobile'
import Assets from '../components/Assets'
import gd from '../Assets/browser 1 (2).png'
import ui from '../Assets/design 1.png'
import Aos from 'aos'
import Autoslider from '../components/Autoslider'
import 'aos/dist/aos.css'
import de from '../Assets/money 1.png'
import logo from '../Assets/Logo2.png'
import prd from '../Assets/browser 1 (3).png'
import mad from '../Assets/design 1 (1).png'
import ht from '../Assets/design 2.png'
import b from '../Assets/Crate Image.png'
import v from '../video/Demoreel Agni Studios.mp4'
import '../style/home.css'
import { motion } from 'framer-motion'
import { AnimatePresence } from 'framer-motion'
import Navbar from '../components/Navbar'
import Slider from '../components/Slider'
function Home() {

  useEffect(()=>{
    Aos.init({
      duration:2000
    })
  })

  return (
    <>
    <AnimatePresence>
    <motion.div className='headv' style={{width:"100%",backgroundColor:"rgba(0,0,0,0.3)"}} 
    initial={{opacity:0}}
        animate={{opacity:1}}
        transition={{ ease: "easeInOut", duration: 0.7 }}
        exit={{opacity:0}}>
    <div style={{position:"fixed",width:"100%",zIndex:"10",top:"0px"}}>
    <Navbar></Navbar>
    </div>

    <div className='log' data-aos="fade-right">
<img src={logo}></img>
</div>
    <video className='videoe' autoPlay muted loop id="myVideo" style={{marginTop:"0px"}}>
  <source src={v} type="video/mp4" />
</video>

    </motion.div>
    <div className='containe' style={{marginTop:"5vh"}} >
      <div className=' homse'>
        <h1 className='text-center'>OUR SERVICES</h1>
      </div>
      <div className='container servi'>
      <center>
      <div className='row '>

      <div className='col' data-aos="zoom-in"
    data-aos-offset="300"
    data-aos-delay="50"
    data-aos-duration="800"
    data-aos-easing="ease-in-out"
    data-aos-once="true">
        <div className='box'>
          <div className='row justify-content-center'>
            <img className='text-center' src={a}></img>
          </div>
          <div className='row text-center'>
            <h1>3D Modelling</h1>
            <p>Crispiest renders and playful 3D models with our expert artists</p>
            <div>
              {/* <a><p>KNOW MORE</p></a> */}
            </div>
          
          </div>
        </div>
      </div>

      <div className='col'  data-aos="zoom-in"
    data-aos-offset="300"
    data-aos-delay="50"
    data-aos-duration="800"
    data-aos-easing="ease-in-out"
    data-aos-once="true">
      <div className='box'>
          <div className='row justify-content-center'>
            <img className='text-center' src={vr}></img>
          </div>
          <div className='row text-center'>
            <h1>VR/AR</h1>
            <p>Get immersed with the spectacular experience curated by the best AR/VR artists</p>
            <div>
              {/* <a><p>KNOW MORE</p></a> */}
            </div>
          
          </div>
        </div>
      </div>
      <div className='col'  data-aos="zoom-in"
    data-aos-offset="300"
    data-aos-delay="50"
    data-aos-duration="800"
    data-aos-easing="ease-in-out"
    data-aos-once="true">
      <div className='box'>
          <div className='row justify-content-center'>
            <img className='text-center' src={gd}></img>
          </div>
          <div className='row text-center'>
            <h1>Game Development</h1>
            <p>Get polished and visually apealing 2D/3D games made by our expert
developers</p>
            <div>
              {/* <a><p>KNOW MORE</p></a> */}
            </div>
          
          </div>
        </div>
      </div>
      <div className='col'  data-aos="zoom-in"
    data-aos-offset="300"
    data-aos-delay="50"
    data-aos-duration="800"
    data-aos-easing="ease-in-out"
    data-aos-once="true">
      <div className='box'>
          <div className='row justify-content-center'>
            <img className='text-center' src={ui}></img>
          </div>
          <div className='row text-center'>
            <h1>UI/UX</h1>
            <p>UI that fits your product and UX that makes it easier for your customers</p>
            <div>
              {/* <a><p>KNOW MORE</p></a> */}
            </div>
          
          </div>
        </div>
      </div>
      </div>
      <div className='row'>
      <div className='col'  data-aos="zoom-in"
    data-aos-offset="300"
    data-aos-delay="50"
    data-aos-duration="800"
    data-aos-easing="ease-in-out"
    data-aos-once="true">
      <div className='box'>
          <div className='row justify-content-center'>
            <img className='text-center' src={de}></img>
          </div>
          <div className='row text-center'>
            <h1>3D Environment</h1>
            <p>Realistic/Stylized environments that best fit for your game or immersive experience.</p>
            <div>
              {/* <a><p>KNOW MORE</p></a> */}
            </div>
          
          </div>
        </div>
      </div>
      <div className='col'  data-aos="zoom-in"
    data-aos-offset="300"
    data-aos-delay="70"
    data-aos-duration="800"
    data-aos-easing="ease-in-out"
    data-aos-once="true">
      <div className='box'>
          <div className='row justify-content-center'>
            <img className='text-center' src={prd}></img>
          </div>
          <div className='row text-center'>
            <h1>Product Ad Render</h1>
            <p>Sell your product better with ad that will blow customers mind</p>
            <div>
              {/* <a><p>KNOW MORE</p></a> */}
            </div>
          
          </div>
        </div>
      </div>
      <div className='col'  data-aos="zoom-in"
    data-aos-offset="300"
    data-aos-delay="50"
    data-aos-duration="800"
    data-aos-easing="ease-in-out"
    data-aos-once="true">
      <div className='box'>
          <div className='row justify-content-center'>
            <img className='text-center' src={mad}></img>
          </div>
          <div className='row text-center'>
            <h1>Mobile App Development</h1>
            <p>Weather it is a game or social media app we are capable of ticking off your needs</p>
            <div>
              {/* <a><p>KNOW MORE</p></a> */}
            </div>
          
          </div>
        </div>
      </div>
      <div className='col'  data-aos="zoom-in"
    data-aos-offset="300"
    data-aos-delay="50"
    data-aos-duration="800"
    data-aos-easing="ease-in-out"
    data-aos-once="true">
      <div className='box'>
          <div className='row justify-content-center'>
            <img className='text-center' src={ht}></img>
          </div>
          <div className='row text-center'>
            <h1>HTML 5</h1>
            <p>Make your website more interesting by adding 3D models and cool visuals to make it more interactive and fun</p>
            <div>
              {/* <a><p>KNOW MORE</p></a> */}
            </div>
          
          </div>
        </div>
      </div>
      </div>
      </center>
      </div>
    </div>
    <div className='contai' style={{height:"100vh",width:"100%", justifyContent:"center",alignItems:"center",display:"flex"}}>
      <div className='container' >
        <div className='row'>
          <div className='col-sm-6 text-center'>
            <img  src={b} style={{width:"100%"}}  data-aos="fade-right"
    data-aos-offset="200"
    data-aos-delay="50"
    data-aos-duration="1000"
    data-aos-easing="ease-in-out"
    data-aos-once="true"></img>
          </div>
          <div className='col-sm-6' style={{justifyContent:"center",alignItems:"center",display:"flex"}}>
            <div className='text-center rights'>
              <h1>WHO ARE WE?</h1>
              <p>Design is where art and science breaks even. Which has led  our team of engineers and designers collaborating to establish a company offering various services. Our expertise includes game production, 3D modeling, AR/VR experience and other project types. we are eager to undertake new ventures in these areas.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='conta'>
      <div className='text-center feat'>
        <h1>FEATURED PROJECTS</h1>
      </div>
      <div className='' style={{marginTop:"0px",width:"100%",overflow:"hidden",display:"flex",justifyContent:"center",alignItems:"center"}}>
        <Slider></Slider>
      </div>
    </div>
    <div style={{marginTop:"10vh"}}>
      <Mobile></Mobile>
    </div>
    <div style={{marginTop:"10vh"}}>
      <Assets></Assets>
    </div>
    <div style={{marginTop:"10vh"}}>
      <Footer></Footer>
    </div>
    </AnimatePresence>
    </>
  )
}

export default Home
