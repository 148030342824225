import React, { useEffect } from 'react'
import '../style/mobile.css'
import a from '../Assets/UI Phone.png'
import Aos from 'aos'

function Mobile() {

    useEffect(()=>{

    })
    return (
        <div className='container-fluid' style={{justifyContent:"center",alignItems:"center",display:"flex"}}>
            <div className='container'>
               
                <div className='row'>
                <div className='col-sm-8'  style={{justifyContent:"center",alignItems:"center",display:"flex"}}>
       
        <div className='row mobilero'>

        <div> 
            <h1>WHY US?</h1>
        </div>
            <div className='col-sm-6 x'>
                <div>
                    <p className='p1mo'>AAA QUALITY ASSETS</p>
                    <p className='p2mo' data-aos="fade-right"  data-aos-offset="300"
    data-aos-delay="50"
    data-aos-duration="1000"
    data-aos-easing="ease-in-out"
    data-aos-once="true">We believe in making best quality assets so that your project will be visually appealing. Our team is capable of making environment of your need. All your needs will be ticked off. Making beautiful and performant environment is our specialty</p>
                </div>
            </div>

            <div className='col-sm-6 x'>
                <div>
                    <p className='p1mo'>TIME MANAGEMENT</p>
                    <p className='p2mo' data-aos="fade-right"  data-aos-offset="300"
    data-aos-delay="50"
    data-aos-duration="1000"
    data-aos-easing="ease-in-out"
    data-aos-once="true">Ensuring product quality remains paramount; however, the timely completion of the required deliverables is of utmost importance. Our team possesses the necessary skills and expertise to meet deadlines while maintaining exceptional product quality.</p>
                </div>
            </div>

            <div className='col-sm-6 x'>
                <div>
                    <p className='p1mo'>EXPERIENCED TEAM</p>
                    <p className='p2mo' data-aos="fade-right"  data-aos-offset="300"
    data-aos-delay="50"
    data-aos-duration="1000"
    data-aos-easing="ease-in-out"
    data-aos-once="true">Our team has passion for art and game development which is essential for getting expected results. We have worked on several projects before which makes us best for fulfilling your requirements. </p>
                </div>
            </div>


            <div className='col-sm-6 x'>
                <div>
                    <p className='p1mo'>SUITABLE USER EXPERIENCE</p>
                    <p className='p2mo' data-aos="fade-right"  data-aos-offset="300"
    data-aos-delay="50"
    data-aos-duration="1000"
    data-aos-easing="ease-in-out"
    data-aos-once="true">Attention to detail is really important when you are creating product for customers. Which is why we do complete research before start working on any features which can make product more suitable for users. </p>
                </div>
            </div>
        </div>
        </div>
        <div className='col-sm-4 text-center'>
            <img src={a} className='imgmo' data-aos="fade-left"  data-aos-offset="300"
    data-aos-delay="50"
    data-aos-duration="1000"
    data-aos-easing="ease-in-out"
    data-aos-once="true"></img>
        </div>

                </div>
            </div>
        </div>
    )
}

export default Mobile
