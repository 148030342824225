import Footer from "./components/Footer";
import Contact from "./Pages/Contact";
import Navbar from "./components/Navbar";
import About from "./Pages/About";
import Slider from "./components/Slider";
import Home from "./Pages/Home";
import Mobile from "./components/Mobile";
import Portfolio from "./Pages/Portfolio";
import Showcase from "./components/Showcase";
import { BrowserRouter as Router, Routes,Route,Link } from 'react-router-dom';
import Assets from "./components/Assets";
import Autoslider from "./components/Autoslider";

function App() {
  return (
    <div className="App">.
    <Router>
   
        <Routes>
          <Route exact path='/' element={<Home></Home>}></Route>
          <Route exact path='/about' element={<About></About>}></Route>
          <Route exact path='/contact' element={<Contact></Contact>}></Route>
          <Route exact path='/portfolio' element={<Portfolio></Portfolio>}></Route>
        </Routes>
        </Router>
    </div>
  );
}

export default App;
